import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useInterviewResponses } from "hooks";
import { INTERVIEW_STATUSES } from "constants";
import { AdvancedButton } from "components/Buttons";

/**
 * ProfileInterviewsDeleteButton
 * @param {String}    profileId
 * @param {String}    interview
 * @param {Boolean}   hasPermission
 */
const ProfileInterviewsDeleteButton = ({ profileId, interview, hasPermission }) => {
  const { handleDeleteInterviewResponse } = useInterviewResponses(
    { filters: { profileId, status: INTERVIEW_STATUSES.completed } },
    true
  );

  if (!hasPermission) return null;

  const disabled = !interview || interview?.deletedAt;

  return (
    <AdvancedButton
      options={[
        {
          label: <FormattedMessage id="Profile.ProfileInterviews.DeleteInterviewButton.Title" />,
          onClick: () => handleDeleteInterviewResponse(interview.id),
        },
      ]}
      disabled={disabled}
    />
  );
};

ProfileInterviewsDeleteButton.propTypes = {
  profileId: PropTypes.string,
  interview: PropTypes.string,
  hasPermission: PropTypes.bool,
};

export default ProfileInterviewsDeleteButton;
