import { useEffect, useState, useMemo } from "react";
import { GET_PULSE } from "graphql/queries";
import { useQueryData, useTeam } from "hooks";
import { getMonthsBetween } from "utils";
import { FEEDBACK_OPTIONS } from "constants/index";
import { PULSE_MODE } from "components/Squads/Pulse";

const keyName = "pulse";

export default function usePulse({ teamId, startDate, endDate, mode } = {}) {
  const { data: team } = useTeam({ id: teamId });
  const [membersData, setMembersData] = useState({});
  const isSquadDynamics = mode === PULSE_MODE.profile;

  const {
    loading,
    data: response,
    error,
    refetch,
  } = useQueryData({
    queryName: GET_PULSE,
    keyName,
    skip: !teamId,
    variables: {
      teamId,
      filters: {
        startDate,
        endDate,
      },
      mode,
    },
  });
  const columns = useMemo(
    () =>
      isSquadDynamics
        ? Object.keys(membersData).sort((a, b) => a.localeCompare(b))
        : getMonthsBetween(startDate, endDate),
    [membersData, isSquadDynamics, startDate, endDate]
  );
  const externalKey = isSquadDynamics
    ? new Date(startDate).toLocaleDateString("en-US", { year: "numeric", month: "short" })
    : team?.name;
  const teamMembers = useMemo(() => getTeamMembers(response?.[keyName]), [response]);
  const data = useMemo(
    () => getTableData(membersData, columns, teamMembers, externalKey),
    [columns, membersData, teamMembers, externalKey]
  );
  const questionText = useMemo(() => response && response[keyName] && response[keyName][0]?.questionText, [response]);

  useEffect(() => {
    if (response && response[keyName]) {
      setMembersData((isSquadDynamics ? groupByAssesseeAndMonth : groupByAssessorAndMonth)(response[keyName]));
    }
  }, [response, mode]);

  return {
    loading,
    data,
    error,
    refetch,
    columns,
    questionText,
  };
}

// Transform the members data in the form of rows and columns
const getTableData = (data, columns, teamMembers, externalKey) =>
  teamMembers.map((profile) => {
    const row = { profile };

    columns.forEach((key) => {
      const { feedback, openFeedback } =
        data[profile?.name]?.[key]?.[externalKey] || data[key]?.[externalKey]?.[profile?.name] || {};
      const feedbackOption = FEEDBACK_OPTIONS[feedback] || FEEDBACK_OPTIONS.default;

      row[key] = { value: feedbackOption, label: feedback, description: openFeedback };
    });

    return row;
  });

// Group the plain data to the assessor and month wise
const groupByAssessorAndMonth = (data) =>
  data.reduce((result, { feedback, openFeedback, assessee, assessor, month, year }) => {
    const assesseeName = assessee.name;
    const assessorName = assessor.name;
    const key = `${month} ${year}`;

    if (!result[assessorName]) {
      result[assessorName] = {};
    }

    if (!result[assessorName][key]) {
      result[assessorName][key] = {};
    }

    result[assessorName][key][assesseeName] = { feedback, openFeedback, profile: assessee };

    return result;
  }, {});

// Group the plain data to the assessor and month wise
const groupByAssesseeAndMonth = (data) =>
  data.reduce((result, { feedback, openFeedback, assessee, assessor, month, year }) => {
    const assesseeName = assessee.name;
    const assessorName = assessor.name;
    const key = `${month} ${year}`;

    if (!result[assesseeName]) {
      result[assesseeName] = {};
    }

    if (!result[assesseeName][key]) {
      result[assesseeName][key] = {};
    }

    result[assesseeName][key][assessorName] = { feedback, openFeedback, profile: assessor };

    return result;
  }, {});

// Find all the team members the selected person has worked with from the given data
const getTeamMembers = (data = []) =>
  Array.from(
    data.reduce((res, f) => {
      res.add(f.assessor);
      return res;
    }, new Set())
  );
