import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";
import { breakpoints, colors, fontSize, margins } from "style";
import { formatNumber } from "utils";
import { TextLoader } from "components/Loader";
import { useParamFunctions } from "hooks";

/**
 * ClassicPaginate
 *
 * @param {Number}    resultsPerPage
 * @param {Number}    totalCount
 * @param {Any}       rightContainer
 * @param {Number}    currentPage
 * @param {Boolean}   isTop
 * @param {Boolean}   loading
 * @param {String}    countMessage
 */
const ClassicPaginate = ({
  resultsPerPage,
  totalCount,
  rightContainer,
  currentPage: defaultPage,
  isTop,
  countMessage,
  loading,
  loadingMore,
}) => {
  const location = useLocation();
  const [searchParams] = useSearchParams({});
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const [perPage, setPerPage] = useState(resultsPerPage);
  const [totalPages, setTotalPages] = useState();
  const [pageNumbers, setPageNumbers] = useState([]);
  const [countStart, setCountStart] = useState(0);
  const [countEnd, setCountEnd] = useState(0);
  const { setParams } = useParamFunctions();

  const handleClick = (p) => {
    setCurrentPage(p);
    setParams("page", p, false);
  };

  /**
   * @description On location change, set the page
   */
  useEffect(() => {
    setCurrentPage(searchParams.get("page") ? parseInt(searchParams.get("page")) : 1);
  }, [location]);

  useEffect(() => {
    const resultsStart = resultsPerPage * (currentPage - 1);
    const resultsEnd = resultsPerPage * currentPage;
    const resultsRemainder = totalCount % resultsPerPage;
    const isLastPage = currentPage === totalPages;

    setCountStart(resultsStart + 1);
    setCountEnd(isLastPage && resultsRemainder ? resultsStart + resultsRemainder : resultsEnd);
  }, [resultsPerPage, currentPage, totalCount, totalPages]);

  useEffect(() => {
    const displayPageNumbers = 10;
    const pNum = [];

    if (totalPages <= displayPageNumbers) {
      for (let i = 1; i <= totalPages; i++) {
        pNum.push(i);
      }
    } else {
      const middlePage = Math.max(1, currentPage - 5);
      const remainingPages = displayPageNumbers - 1;
      const startPage = Math.min(middlePage, totalPages - remainingPages);

      for (let i = startPage; i < startPage + displayPageNumbers; i++) {
        pNum.push(i);
      }
    }

    setPageNumbers(pNum);
  }, [totalPages, currentPage]);

  useEffect(() => {
    if (totalCount && perPage) {
      setTotalPages(Math.ceil(totalCount / perPage));
    }
  }, [totalCount, perPage]);

  useEffect(() => {
    if (resultsPerPage) {
      setPerPage(resultsPerPage);
    }
  }, [resultsPerPage]);

  useEffect(() => {
    if (defaultPage) {
      setCurrentPage(defaultPage);
    }
  }, [defaultPage]);

  return (
    <div css={styles.card_container(isTop)}>
      <div css={styles.container}>
        <div css={styles.start}>
          {loading ? (
            <TextLoader width="30rem" />
          ) : (
            <FormattedMessage
              id="Pagination.ShowingResults"
              values={{
                page: (
                  <FormattedMessage
                    id="Pagination.ShowingResults.Page"
                    values={{
                      start: <span css={styles.bold}>{formatNumber(countStart)}</span>,
                      end: <span css={styles.bold}>{formatNumber(countEnd)}</span>,
                    }}
                  />
                ),
                entity: (
                  <span css={styles.count}>
                    <FormattedMessage
                      id={countMessage}
                      values={{
                        formattedNumber: formatNumber(totalCount),
                        count: totalCount,
                      }}
                    />
                  </span>
                ),
              }}
            />
          )}
        </div>
        <div css={styles.page_container}>
          {loading || loadingMore ? (
            <TextLoader width="20rem" />
          ) : (
            <Fragment>
              {!(loading || loadingMore) && totalPages && (
                <button
                  css={styles.button(false)}
                  onClick={() => handleClick(currentPage - 1)}
                  disabled={loading || currentPage === 1 || totalPages === 0}
                >
                  <Icon type={ICON_TYPE.arrowLeft} size={ICON_SIZE.large} css={styles.icon} />
                </button>
              )}
              {pageNumbers.map((pageNumber) => (
                <button
                  key={pageNumber}
                  css={styles.button(pageNumber === currentPage)}
                  onClick={() => handleClick(pageNumber)}
                  disabled={pageNumber === currentPage}
                >
                  {pageNumber}
                </button>
              ))}
              {!(loading || loadingMore) && totalPages && (
                <button
                  css={styles.button(false)}
                  onClick={() => handleClick(currentPage + 1)}
                  disabled={loading || currentPage === totalPages || totalPages === 0}
                >
                  <Icon type={ICON_TYPE.arrowRight} size={ICON_SIZE.large} css={styles.icon} />
                </button>
              )}
            </Fragment>
          )}
        </div>
        {rightContainer && <div css={styles.end}>{rightContainer}</div>}
      </div>
    </div>
  );
};

const styles = {
  card_container: (isTop) => css`
    margin-left: -${margins.normal};
    margin-right: -${margins.normal};
    padding: 0 ${margins.normal};

    ${isTop
      ? `
      border-bottom: 1px solid ${colors.grayAnatomyLight5};
      padding-bottom: 2rem;
    `
      : `
      border-top: 1px solid ${colors.grayAnatomyLight5};
      padding-top: 2rem;
    `}

    @media (max-width: ${breakpoints.portrait}) {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
      margin-right: -2.5rem;
      margin-left: -2.5rem;
    }

    @media (max-width: ${breakpoints.mobile}) {
      padding-right: 2rem;
      padding-left: 2rem;
      margin-right: -2rem;
      margin-left: -2rem;
      padding-top: 2rem;
    }
  `,
  container: css`
    display: flex;
    justify-content: center;
    list-style: none;
    font-size: ${fontSize.small};
    font-weight: 600;
    gap: 2.5rem;
    min-height: 2.5rem;
    align-items: center;

    @media (max-width: ${breakpoints.mobile}) {
      flex-direction: column;
      justify-content: center;
      gap: 0;
    }
  `,
  start: css`
    flex: 1;
    font-weight: 400;
    color: ${colors.purpleRainDark2};
    font-size: ${fontSize.small};

    @media (max-width: ${breakpoints.mobile}) {
      flex: auto;
      width: 100%;
      text-align: center;
      border-bottom: 1px solid ${colors.grayAnatomyLight5};
      padding-bottom: 1rem;
      margin-bottom: 0.5rem;
    }
  `,
  bold: css`
    font-weight: 600;
  `,
  count: css`
    font-weight: 500;
    background: ${colors.grayAnatomyLight5};
    padding: 0.5rem 1rem;
    border-radius: 3rem;
  `,
  end: css`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    font-weight: 400;
    color: ${colors.purpleRainDark2};
    font-size: ${fontSize.xsmall};
  `,
  page_container: css`
    display: flex;
    gap: 0.25rem;
    margin-left: auto;
    align-items: center;

    @media (max-width: ${breakpoints.mobile}) {
      margin-left: 0;
    }
  `,
  button: (selected) => css`
    background: ${selected ? colors.purpleRainBase : `none`};
    font-size: ${fontSize.xxsmall};
    padding: 0;
    border: 0;
    border-radius: 0.6rem;
    color: ${selected ? `#fff` : colors.purpleRainDark2};
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
    font-weight: ${selected ? `600` : `400`};

    &:hover {
      background: ${selected ? colors.purpleRainBase : colors.grayAnatomyLight4};
    }

    &:disabled {
      background: ${selected ? colors.purpleRainBase : `none`} !important;
      color: ${selected ? `#fff` : colors.grayAnatomyLight3};
      cursor: not-allowed;
    }
  `,
  icon: css`
    color: inherit;
  `,
};

ClassicPaginate.defaultProps = {
  isTop: false,
  countMessage: "Pagination.ShowingResults.Count",
};

ClassicPaginate.propTypes = {
  resultsPerPage: PropTypes.number,
  totalCount: PropTypes.number,
  rightContainer: PropTypes.any,
  currentPage: PropTypes.number,
  isTop: PropTypes.bool,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  countMessage: PropTypes.string,
};

export default ClassicPaginate;
