import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useAuth } from "hooks";
import { Grid, PageWrapper } from "components/Containers";
import {
  ProfileInterviewsDeleteButton,
  ProfileInterviewsDropdown,
  ProfileNav,
  ProfileVideoInterviewsList,
} from "components/Profile";
import PageTitle from "components/PageTitle";

/**
 * ProfileVideoInterviews
 */
const ProfileVideoInterviews = () => {
  const { id } = useParams();
  const intl = useIntl();
  const { isMember, isAdmin } = useAuth();
  const [interviews, setInterviews] = useState();
  const [selectedInterview, setSelectedInterview] = useState();

  useEffect(() => {
    if (interviews) {
      setSelectedInterview(interviews.find((interview) => !interview.deletedAt));
    }
  }, [interviews]);

  return (
    <>
      <ProfileNav id={id} />
      <PageWrapper>
        <Helmet>
          <title>{intl.formatMessage({ id: "Meta.Profiles.VideoInterviews.Title" })}</title>
        </Helmet>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle
              {...(isMember
                ? {
                    title: <FormattedMessage id="Profile.VideoInterviews.Title" />,
                  }
                : {
                    subtitle: <FormattedMessage id="Profile.VideoInterviews.Title" />,
                  })}
              actionBar={
                <>
                  <div>
                    <ProfileInterviewsDropdown
                      data={interviews}
                      onChange={(_name, val) => {
                        setSelectedInterview(interviews?.find((interview) => interview.id === val));
                      }}
                      value={selectedInterview?.id}
                      hasPermission={isAdmin}
                    />
                  </div>
                  <ProfileInterviewsDeleteButton
                    profileId={id}
                    interview={!selectedInterview?.deletedAt ? selectedInterview : null}
                    hasPermission={isAdmin}
                  />
                </>
              }
            />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <ProfileVideoInterviewsList
              profileId={id}
              selectedInterview={selectedInterview}
              setInterviews={setInterviews}
            />
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default ProfileVideoInterviews;
