import { gql } from "@apollo/client";

const GET_CHALLENGE_RESPONSES = gql`
  query challengeResponses(
    $profileId: ID
    $disablePagination: Boolean
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    challengeResponses(
      profileId: $profileId
      disablePagination: $disablePagination
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      nodes {
        id
        profileId
        status
        score
        maxScore
        reportUrl
        type
        tasks
        structuredTasks
        startedAt
        completedAt
        cheatingFlag
        cheatingDetails
        challenge {
          id
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export { GET_CHALLENGE_RESPONSES };
