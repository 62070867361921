import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { useInView } from "react-intersection-observer";
import { Header } from "components/DisplayBox";
import { H3 } from "components/Text";
import Card from "components/Card";
import { PrimaryLink } from "components/Links";
import { DIRECTION, LINKS, TEAM_TYPES } from "constants/index";
import { useTeams, useAuth } from "hooks";
import { Col, Grid, Row } from "components/Containers";
import { Spinner } from "components/Loader";
import { MessagesError } from "components/Messages";
import { PAGINATION_TYPE } from "components/Pagination";
import { SquadIcon, SquadMetrics, SquadSignalNotice } from "components/Squads";
import DataTable from "components/DataTable";
import {
  SquadMetricsVolume,
  SquadMetricsActivity,
  SquadMetricsEfficiency,
  SquadMetricsPulse,
  SquadMetricsTeamDynamics,
  SquadMetricsClientSatisfaction,
} from "components/Squads/SquadMetricsCells";
import { UserPhotoList } from "components/User";

/**
 * DashboardSummaryContainer
 *
 * @param {String} profileId
 * @param {Number} resultsPerPage
 * @param {Boolean} isCardView
 */
const DashboardSummaryContainer = ({ profileId, resultsPerPage, isCardView, ...props }) => {
  const { isAdmin } = useAuth();
  const { loading, loadingMore, data, error, hasNextPage, handleNextPage } = useTeams({
    profileId,
    resultsPerPage,
    paginationType: PAGINATION_TYPE.append,
    variables: {
      sort: "created_at",
      sortDirection: DIRECTION.descending,
      limit: null,
    },
  });
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage && !loading && !loadingMore) {
      handleNextPage();
    }
  }, [inView]);

  // Hide if not an admin (null will raise error that way)
  if (!isAdmin) return null;

  if (error) return <MessagesError />;

  if (isCardView) {
    return (
      <>
        <Grid>
          {data?.map((teamProps) => (
            <Grid.col start={1} end={13} key={teamProps?.id}>
              <DashboardSummaryCard team={teamProps.assignments} {...teamProps} {...props} loading={loading} />
            </Grid.col>
          ))}
          <div ref={ref} />
        </Grid>
        {(loading || loadingMore) && <Spinner margin={`1rem auto`} />}
      </>
    );
  } else {
    return (
      <Card>
        <DataTable data={data} loading={loading} columns={COLUMN_DATA} {...props} />
        {(loading || loadingMore) && <Spinner margin={`1rem auto`} />}
        <div ref={ref} />
      </Card>
    );
  }
};

const COLUMN_DATA = [
  {
    label: <FormattedMessage id="Name" />,
    cell: ({ organization, name, slug }) => (
      <Col gap="0.5rem">
        <span>{organization?.name}</span>
        <PrimaryLink to={LINKS.squad_profile(slug)} routerEnabled>
          {name}
        </PrimaryLink>
      </Col>
    ),
    width: "28%",
  },
  {
    label: <FormattedMessage id="Dashboard.MetricsCard.TitleType_ACTIVITY" />,
    cell: ({ id }) => <SquadMetricsActivity teamId={id} />,
    width: "12%",
  },
  {
    label: <FormattedMessage id="Dashboard.MetricsCard.TitleType_VOLUME" />,
    cell: ({ id }) => <SquadMetricsVolume teamId={id} />,
    width: "12%",
  },
  {
    label: <FormattedMessage id="Dashboard.MetricsCard.TitleType_EFFICIENCY" />,
    cell: ({ id }) => <SquadMetricsEfficiency teamId={id} />,
    width: "12%",
  },
  {
    label: <FormattedMessage id="Dashboard.MetricsCard.TitleType_PULSE" />,
    cell: ({ id }) => <SquadMetricsPulse teamId={id} />,
    width: "12%",
  },
  {
    label: <FormattedMessage id="Dashboard.MetricsCard.TitleType_TEAM_DYNAMICS" />,
    cell: ({ id }) => <SquadMetricsTeamDynamics teamId={id} />,
    width: "12%",
  },
  {
    label: <FormattedMessage id="Dashboard.MetricsCard.TitleType_STAKEHOLDER_SATISFACTION" />,
    cell: ({ id }) => <SquadMetricsClientSatisfaction teamId={id} />,
    width: "12%",
  },
];

/**
 * DashboardSummaryCard
 *
 * @param {Number}   id
 * @param {String}   marginSize
 * @param {String}   slug
 * @param {Object}   organization
 * @param {Array}    gitRepositories
 * @param {String}   name
 * @param {Boolean}  loading
 * @param {Array}    team
 */
const DashboardSummaryCard = ({
  id,
  marginSize,
  slug,
  organization,
  gitRepositories,
  name,
  loading,
  team,
  ...props
}) => (
  <StyledCard {...props}>
    <Header marginSize={marginSize}>
      <Row>
        <SquadIcon name={name} />
        <Col>
          <H3 loading={loading}>{organization?.name}</H3>
          {name}
        </Col>
      </Row>
      <Row>
        <SquadSignalNotice teamData={{ id, slug, organization, name, gitRepositories, ...props }} />
        {!loading && (
          <PrimaryLink to={LINKS.squad_profile(slug)} withMoreIcon routerEnabled>
            <FormattedMessage id="Dashboard.DashboardSummaryCard.CTA" />
          </PrimaryLink>
        )}
      </Row>
    </Header>
    <UserPhotoList
      users={team?.map((item) => item.profile)}
      thumbLimit={5}
      thumbCount={team?.length}
      thumbSize="4rem"
    />
    {!loading && (
      <SquadMetrics
        teamId={id}
        teamSlug={slug}
        hideHeader={true}
        showAll={team?.teamType !== TEAM_TYPES.external}
        {...props}
      />
    )}
  </StyledCard>
);

const StyledCard = styled(Card)`
  flex: 1;
`;

DashboardSummaryContainer.propTypes = {
  profileId: PropTypes.string,
  resultsPerPage: PropTypes.number,
  isCardView: PropTypes.bool,
};

DashboardSummaryContainer.defaultProps = {
  resultsPerPage: 5,
  isCardView: true,
};

DashboardSummaryCard.propTypes = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  marginSize: PropTypes.string,
  organization: PropTypes.object,
  gitRepositories: PropTypes.string,
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  team: PropTypes.array,
};

export default DashboardSummaryContainer;
