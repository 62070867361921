import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Select } from "components/Form";
import { formatDate } from "utils";

/**
 * ProfileInterviewsDropdown
 *
 * @param {Boolean} hasPermission
 * @param {Array} data
 */
const ProfileInterviewsDropdown = ({ data, hasPermission, ...props }) => {
  if (!hasPermission || !data) return null;

  return (
    <Select
      options={[...data]
        .sort((a) => (a.deletedAt === null ? -1 : 1)) // Sort by deletedAt, nulls first.
        .map((interviewResponse) => ({
          value: interviewResponse.id,
          label: (
            <FormattedMessage
              id={`Profile.VideoInterviews.SelectInterview.Value`}
              values={{
                date: formatDate(interviewResponse.createdAt),
                status: interviewResponse.deletedAt ? "Archived" : "Current",
              }}
            />
          ),
        }))}
      labelKey="label"
      valueKey="value"
      placeholder={<FormattedMessage id="Profile.VideoInterviews.SelectInterview" />}
      {...props}
    />
  );
};

ProfileInterviewsDropdown.propTypes = {
  hasPermission: PropTypes.bool,
  data: PropTypes.array,
};

export default ProfileInterviewsDropdown;
